.nav-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    flex-shrink: 0;
    cursor: pointer;
    border-radius: 50px;
    height: 60px;
    width: 170px;
}

.active-btn-tab {
    background-color: #4bf879;
}

.nav-tab>div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-tab>div>img {
    width: 40px;
    height: auto;
}

.nav-tab>div>h6 {
    margin-bottom: 0 !important;
}


@media screen and (max-width:600px) {
    .nav-tab {
        padding: 8px;
        justify-content: flex-start;
    }
}


@media screen and (max-width:400px) {
    .nav-tab {
        width: 120px !important;
    }

    .nav-tab>div>h6 {
        margin-bottom: 0 !important;
        font-size: 11px !important;
    }

    .nav-tab>div>img {
        width: 28px !important;
    }
}