.landing-page-slider-tabs {
    display: flex;
    transition: all 0.5s;
}

.slider-tabs-icon {
    margin-right: 10px;
    width: 69px;
    height: 69px;
    transition: all 0.5s;
}

.slider-tabs-divider {
    margin-right: 15px;
    transition: all 0.5s;
}

.custom-divider>img {
    display: none;
}

.custom-divider {
    border-right: solid 2px #d0d0d0;
    transition: all 0.5s;
}

.slider-tabs-overlay {
    transition: all 0.5s;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 99;
    height: 114%;
}



.slider-tabs-text>p {
    width: 390px !important;
}

@media screen and (max-width:992px) {
    .slider-tabs-outer-wrapper {
        margin-bottom: 20px;
    }
}

@media screen and (max-width:550px) {
    .slider-tabs-text>p {
        width: 100% !important;
    }
}