/* Author's Custom Styles */


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');


body {
  font-size: 18px;
  line-height: 33px;
  background-color: #fff;
  color: #7B7B7B;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden !important;
  font-weight: 400 !important;
}

h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 40px;
  color: #030621;
}

h3,
h4,
h5 {
  font-family: 'Poppins', sans-serif;
  color: #030621;
}

h2 {
  font-family: 'Poppins', sans-serif;
  color: #030621;
  font-weight: 700;
}

p,
ul {
  margin: 0px;
  margin-bottom: 20px;
}

a,
a:hover,
a:visited,
a:active,
a:focus {
  color: inherit;
  text-decoration: none;
}

.btn {
  font-family: 'Poppins', sans-serif !important;
  background: #030621 !important;
  border: 2px solid #030621 !important;
  font-size: 15px !important;
  color: #fff !important;
  text-align: center !important;
  padding: 8px 20px !important;
  font-weight: 500 !important;
  /* width: 230px !important; */
  transition: 0.3s ease-in-out !important;
  position: relative !important;
  border-radius: 10px !important;
  -webkit-transition: 0.3s ease-in-out !important;
  -moz-transition: 0.3s ease-in-out !important;
  -ms-transition: 0.3s ease-in-out !important;
  -o-transition: 0.3s ease-in-out !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}



.btn-second {
  font-family: 'Poppins', sans-serif !important;
  background: #fff !important;
  font-size: 16px !important;
  border: 2px solid #030621 !important;
  color: #000 !important;
  text-align: center !important;
  padding: 15px 20px !important;
  font-weight: 500 !important;
  width: 230px !important;
  transition: 0.3s ease-in-out !important;
  border-radius: 10px !important;
  -webkit-transition: 0.3s ease-in-out !important;
  -moz-transition: 0.3s ease-in-out !important;
  -ms-transition: 0.3s ease-in-out !important;
  -o-transition: 0.3s ease-in-out !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}

.btn:hover {
  background-color: transparent !important;
  color: #030621 !important;
  border: 2px solid #030621 !important;
}

.btn:focus {
  outline: none !important;
  background: #030621 !important;
  color: #fff !important;
}


.padding-left {
  padding-left: 290px;
}

/* * Section */
.section {
  padding-top: 80px;
  text-align: left;
}

.section h2 {
  font-family: 'Poppins', sans-serif;
  color: #000;
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 35px;
  color: #000;
}

.section h3 {
  font-size: 26px;
  color: #000;
  font-weight: 500;
}

.section h2:first-child {
  margin-top: 0px;
  margin-bottom: 20px;
  color: #000;
}

.section p:last-child,
.section ul:last-child {
  margin-bottom: 0px;
}


.section-padding {
  padding: 0px 140px;
}

.no-padding {
  padding: 0px;
}

.no-margin {
  margin: 0px;
}







/* home-banner */

.home-banner {
  background-image: url('../img/banner-bg.png');
  background-size: cover;
  background-position: center;
  padding-top: 98px;
  position: relative;
}

.home-banner .content-area {
  padding: 170px 0px;
}

.home-banner .content-area h1 span {
  font-weight: 300;
}

.home-banner .content-area h1 {
  color: #030621;
  margin-bottom: 14px;
}

.home-banner .content-area p {
  color: #030621;
  font-size: 18px;
}

.home-banner .content-area .button-area {
  margin-top: 32px;
}

.home-banner .content-area .button-area .btn-get-started {
  width: unset !important;
}

.home-banner .content-area .image-area img {
  margin-bottom: -116px;
  margin-left: 69px;
}

.landing-page-item>.items,
.home-banner .content-area .items {
  display: flex;
  gap: 30px;
  align-items: center;
  margin-top: 40px;
  flex-wrap: wrap;
}

.landing-page-item>.items>.item,
.home-banner .content-area .items .item {
  display: flex;
  align-items: baseline;
}

.landing-page-item>.items>.item>p,
.home-banner .content-area .items .item p {
  font-size: 15px;
  font-weight: 600;
  line-height: 25px;
}

.landing-page-item>.items>.item>i,
.home-banner .content-area .items .item i {
  color: #030621;
  margin-right: 12px;
}


.home-banner .content-area .image-area .anim {
  position: relative;
}

.home-banner .content-area .image-area .anim .mobile {
  display: none;
}

.home-banner .content-area .image-area .icon {
  position: absolute;
  width: 50px;
}

.home-banner .content-area .image-area .icon.fb {
  left: 28%;
  top: 33%;
}

.home-banner .content-area .image-area .icon.insta {
  left: 16%;
  top: 48%;
}

.home-banner .content-area .image-area .icon.twitter {
  left: 14%;
  top: 64%;
}

.home-banner .content-area .image-area .icon.linkedin {
  left: 17%;
  top: 78%;
}

.home-banner .content-area .image-area .icon.slack {
  right: 14%;
  top: 33%;
}

.home-banner .content-area .image-area .icon.whatsapp {
  right: 6%;
  top: 47%;
}

.home-banner .content-area .image-area .icon.pin {
  right: 10%;
  top: 62%;
}

.home-banner .content-area .image-area .icon.redit {
  right: 7%;
  top: 75%;
}

/* home-brands */


.home-brands .items-area {
  display: flex;
  align-items: center;

}


/* home-features */

.home-features .content p {
  font-size: 20px;
  line-height: 24px;
  color: #030621;
}

.home-features .image-area img {
  width: 80%;
}

.home-features .content .features-area ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  padding-right: 15px;
}

.home-features .content .features-area ul li {
  width: 50%;
  font-size: 18px;
  color: #000;
  position: relative;
  margin-top: 36px;
  display: flex;
  align-items: center;
}

.home-features .content .features-area ul li img {
  margin-right: 10px;
}

/* more-saas-tool  */

.more-saas-tool {
  background-color: #F5F5F5;
  padding-top: 0px !important;
  margin-top: 80px;
}

.more-saas-tool .content-area {
  padding: 80px 0px;
}

.more-saas-tool .content-area .items {
  margin-top: 62px;
}

.more-saas-tool .content-area .items .item {
  background-color: #fff;
  padding: 38px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 16%);
}

.more-saas-tool .content-area .items .item p {
  font-size: 22px;
  color: #030621;
  margin-top: 10px;
}

.more-saas-tool .content-area .items .row-item {
  display: grid;
}

.more-saas-tool .content-area .items .button-area {

  margin-top: 62px;
}

.more-saas-tool .content-area .items .button-area a img {
  max-width: 300px;
}


/* home-platform-capabilities */

.home-platform-capabilities {
  background-color: rgba(72, 250, 138, 20%);
  padding-top: 0px !important;
  margin-top: 80px;
}

.home-platform-capabilities .content-area {
  padding: 80px 0px;
}

.home-platform-capabilities .content-area ul {
  list-style-type: none;
  padding: 0;
}

.home-platform-capabilities .content-area ul li {
  background-color: #fff;
  margin-bottom: 20px;
  padding: 20px 30px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  width: 90%;
  position: relative;
}

.home-platform-capabilities .content-area ul li::after {
  font-family: 'fontAwesome';
  content: '\f054';
  position: absolute;
  right: 5%;
  color: #48FA8A;
}

.home-platform-capabilities .content-area .image-area img {
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

/* api-integrations  */

.api-integrations {
  background-image: url('../img/api-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 0px !important;
}

.api-integrations .content-area {
  padding: 80px 0px;
}

.api-integrations .content-area .content {
  display: flex;
  justify-content: space-between;
  color: #030621;
  align-items: end;
}

.api-integrations .content-area .scroll-area .line {
  overflow: hidden;
  white-space: nowrap;
  animation: moveLinear 10s linear infinite;
  transition: animation-play-state 0.3s;
}

.api-integrations .content-area .scroll-area .line img {
  background-color: rgba(86, 88, 106, 20%);
  width: 150px;
  border-radius: 16px;
  height: auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.api-integrations .wrapper {
  margin-top: 70px;
}

:root {
  --color-text: #000;
  --colog-bg: rgba(3, 6, 33, 30%);
  --color-bg-accent: rgba(3, 6, 33, 30%);
  --size: clamp(10rem, 1rem + 40vmin, 20rem);
  --gap: calc(var(--size) / 30);
  --duration: 60s;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));
}

.marquee {
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
}


.marquee_group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: scroll-x var(--duration) linear infinite;
  -webkit-animation: scroll-x var(--duration) linear infinite;
}

.marquee-reverse .marquee_group {
  animation-direction: reverse;
  /* animation-delay: -3s; */
}

@keyframes scroll-x {
  from {
    transform: translateX(var(--scroll-start));
  }

  to {
    transform: translateX(var(--scroll-end));
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  margin: auto;

}

.marquee-reverse .marquee-group img {
  width: 300px;
}

.api-integrations .content-area .button-area {
  margin-top: 50px;
}

.api-integrations .content-area .button-area .btn-start {
  width: unset !important;
}

/* home-faqs */

.home-faqs {
  background-color: #F5F5F5;
  padding-top: 0 !important;
  margin-top: 80px;
}

.home-faqs .content {
  padding: 80px 0px;
}

.home-faqs .faqs-area {
  margin-top: 62px;
}


.accordion {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}


.faq-para-wrapper {
  padding: 0 110px;
}

.home-faqs .faqs-area .accordion .accordion-item {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 6px 15px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  overflow: hidden;
}

.home-faqs .faqs-area .accordion .accordion-item h2 {
  margin-bottom: 0;

}


.accordion-button {
  font-size: 20px;
  font-weight: 500 !important;
  padding: 23px;
}

.accordion-header {
  font-size: 20px !important;
  font-weight: 500 !important;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.accordion-button:not(.collapsed) {
  color: #000 !important;
  background-color: #fff !important;
  box-shadow: none !important;
  outline: 0 !important;
  border: 0px !important;
}

.accordion-button:focus {
  border: 0;
  z-index: 3;
  border-color: unset !important;
  outline: 0;
  box-shadow: none !important;
}

.home-faqs .faqs-area .accordion-item .accordion-button::after {
  background-image: none !important;
  font-family: 'fontAwesome';
  content: '\f078';
  color: #48FA8A;
  font-size: 22px;
  font-weight: 600;
}


/* .accordion-button:not(.collapsed) */
/* home-testimonials */

.home-testimonials .owl-carousel {
  margin-top: 60px;
  position: relative;
}

.home-testimonials .owl-carousel .owl-nav {
  position: absolute;
  right: 9%;
  top: -20%;
}

.home-testimonials .owl-carousel .owl-nav button {
  background-color: #fff;
  color: #48FA8A;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  width: 45px;
  height: 45px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  font-size: 20px;
}

.home-testimonials .owl-carousel .owl-nav button.owl-prev {
  margin-right: 16px;
}

.home-testimonials .owl-carousel .item {
  background-color: #F5F5F5;
  padding: 30px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  margin-right: 40px;
  position: relative;
}

.home-testimonials .owl-carousel .item .review p {
  font-size: 15px;
  line-height: 20px;
}

.home-testimonials .owl-carousel .item .user-area {
  display: flex;
  align-items: center;
}

.home-testimonials .owl-carousel .item .user-area img {
  width: 70px;
  height: 70px;
  border: 2px solid #48FA8A;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.home-testimonials .owl-carousel .item .user-area .info {
  margin-left: 15px;
}

.home-testimonials .owl-carousel .item .user-area .info p.name {
  font-size: 23px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 0px;
}

.home-testimonials .owl-carousel .item .user-area .info p.company {
  font-size: 16px;
  font-weight: 300;
}

.home-testimonials .owl-carousel .item .review {
  margin-top: 25px;
}

.home-testimonials .owl-carousel .item .review ul.star {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
}

.home-testimonials .owl-carousel .item .review ul.star li {
  display: inline-block;
  color: #F8B805;
  font-size: 20px;
}

.owl-carousel .owl-stage {
  margin: 20px 10px;
}

.home-testimonials .owl-carousel .item i.quote {
  position: absolute;
  right: 10%;
  color: #48FA8A;
  font-size: 50px;
  top: 8%;
}


/* footer */

footer {
  background-color: #030621;
  margin-top: 80px;
  padding-top: 64px;
}

footer .main-footer .upper-footer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #7B7B7B;
  padding-bottom: 50px;
}

footer .main-footer .upper-footer .logo img {
  max-width: 220px;
}

footer .main-footer .upper-footer .links ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;

}

footer .main-footer .upper-footer .links ul li {
  display: inline-block;
  padding: 0px 30px;

}

footer .main-footer .upper-footer .links ul li a {
  color: #fff;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
}

footer .main-footer .upper-footer .links ul li a:hover {
  color: #56F444;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
}

.footer-links>h5 {
  font-size: 18px;
  margin-bottom: 20px !important;
}

.footer-links>ul {
  font-size: 14px;
  color: #7b7b7b;
  display: flex !important;
  flex-direction: column !important;
  gap: 1rem !important;
  margin: 0 !important;
  flex-shrink: 0;
}

.footer-links>ul>li>a {
  color: #7b7b7b !important;
}



footer .main-footer .upper-footer .social ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;

}

footer .main-footer .upper-footer .social ul li {
  display: inline-block;
  padding: 0px 10px;
  color: #56F444;
}

footer .main-footer .upper-footer .social ul li a:hover i {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  background-color: #56F444;
  color: #030621;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
}

footer .main-footer .upper-footer .social ul li a i {
  width: 42px;
  height: 42px;
  border: 2px solid #56F444;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
}

footer .main-footer .lower-footer {
  padding: 55px 0px;
}

footer .main-footer .lower-footer h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
}

footer .main-footer .lower-footer ul {
  list-style-type: none;
  padding: 0;
}

footer .main-footer .lower-footer ul li {
  margin-bottom: 4px;
}

footer .main-footer .lower-footer ul li a {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
}

footer .main-footer .lower-footer ul li a:hover {
  color: #56F444;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
}

footer .copyright .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

footer .copyright .content span {
  color: #C1C1C1;
  font-size: 14px;
  font-weight: 300;
}

footer .copyright .content span a {
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
}

footer .copyright .content span a:hover {
  color: #56F444;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
}


/* login and signup pages */



.login-signup {
  display: flex;
}

.login-signup .login-signup-area {
  background-color: #FFFFFF;
  width: 50%;
  padding: 120px 0px 0px 50px;
}

.login-signup .login-signup-area.login {
  margin-top: 70px;
}

.login-signup .login-signup-area .content-area h1 {
  font-size: 35px;
  color: #030621;
  font-weight: 700;
}

.login-signup .login-signup-area .content-area p {
  font-size: 20px;
  color: #030621;
  line-height: 30px;
}

.main .login-signup .bg-with-image .image-area img {
  width: 75%;
}

.login-signup .login-signup-area .button-area .btn-second {
  width: unset !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  padding: 10px 20px;
}

.login-signup .login-signup-area .button-area .btn-second:hover {
  background-color: #030621;
  color: #fff !important;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
}

.login-signup .login-signup-area .button-area .btn-second img {
  margin-right: 20px;
}

.login-signup .bg-with-image {
  background: linear-gradient(to left, #56F444 60%, #48FA8A 82%, #fff 0%);
  width: 45%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  right: 0;
}


.login-signup .login-signup-area .form-area .form-control {
  background-color: #F1F1F3;
  padding: 16px 20px;
  margin-bottom: 20px;
  border: none;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.login-signup .login-signup-area .form-area .form-select {
  background-color: #F1F1F3;
  padding: 16px 40px;
  margin-bottom: 20px;
  border: none;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.login-signup .login-signup-area .form-area .password-field {
  position: relative;
  display: flex;
  align-items: center;
}

.login-signup .login-signup-area .form-area .password-field img {
  float: right;
  position: absolute;
  top: 21px;
  right: 30px;
  width: 20px;
}

.login-signup .login-signup-area .form-area .select {
  position: relative;
  display: flex;
  align-items: center;
}

.login-signup .login-signup-area .form-area .select img {
  float: right;
  position: absolute;
  top: 17px;
  left: 25px;
  width: 20px;
}

::placeholder {
  color: #C1C1C1;
}

.form-area .send-leads {
  display: flex;
  gap: 25px;
  margin-top: 10px;
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
  background-color: #C1C1C1;
  border-color: #C1C1C1;
}

.round input[type="checkbox"]:checked+label:after {
  opacity: 1;
}


.login-signup .login-signup-area .form-area .button-area .btn-sign-up {
  width: 100% !important;
  margin: 30px 0px;
}

.login-signup .login-signup-area .form-area span a {
  color: #030621;
}

/* pricing-page */

.pricing-title-area {
  padding-top: 101px;
}


.pricing-title-area .content-area.blog h1 {
  font-size: 40px;
}

.pricing-title-area .content-area {
  padding: 140px 0px;
  position: relative;
}

.pricing-title-area .content-area.blog {
  padding: 110px 0px;
}

.pricing-title-area .content-area h1 span {
  font-weight: 300;
  color: #030621;
}

.pricing-title-area .content-area h2 {
  font-size: 22px;
  font-weight: 500;
  color: #030621;
}

.pricing-title-area .content-area p {
  font-size: 20px;
  font-weight: 400;
  color: #030621;
  margin-top: 18px;
}

.pricing-title-area .content-area .image-area img {
  position: absolute;
}

.pricing-title-area .content-area .image-area img.hand {
  right: 0%;
  top: 0%;
}

.pricing-title-area .content-area .image-area img.dollar {
  left: 0%;
  bottom: 0;
}


.amp-pricing .switch-area {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.amp-pricing .switch-area .bill-label p {
  font-size: 18px;
  color: #000;
  margin: 0px 15px;
  font-weight: bold;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}



.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #3CE528;
}

input:focus+.slider {
  box-shadow: 0 0 1px #0350B6;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.price {
  display: none;
  margin-top: 96px;
}

.price.active {
  display: block;
}

.amp-pricing .row-item {
  display: grid;
}

.amp-pricing .price .item {
  background-color: #fff;
  padding: 30px 25px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  transition: 0.3s ease-in-out;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.16);
}

.amp-pricing .price .item:hover {
  background: linear-gradient(to bottom, #58F546, #48FA8A);
  color: #030621;
  transform: translateY(-20px);
  -webkit-transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  -o-transform: translateY(-20px);
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
}

.amp-pricing .price .item .button-area {
  text-align: center;
}

.amp-pricing .price .item .button-area .magnet-button {
  background: #fff !important;
  border: 2px solid #030621 !important;
  color: #000 !important;
  margin: 25px 0px !important;
}

.amp-pricing .price .item:hover .button-area .magnet-button {
  background: #030621 !important;
  border: 2px solid #030621 !important;
  color: #fff !important;
  margin: 32px 0px !important;
}

.amp-pricing .price .item .content-top p {
  font-size: 16px;
  line-height: 26px;
}

.amp-pricing .price .item .content-top p.tag {
  font-size: 24px;
  font-weight: 500 !important;
  text-align: left;
  color: #030621;
  margin-bottom: 33px;
}

.amp-pricing .price .item:hover .content-top h3 {
  color: #030621;
}

.amp-pricing .price .item:hover p {
  color: #030621;
}

.amp-pricing .price .item .content-top h3 {
  font-size: 35px;
  color: #030621;
  text-align: center;
  font-weight: 700;
}

.amp-pricing .price .item .content-top h3 span {
  font-size: 17px;
  font-weight: normal;
  color: #030621;
}

.amp-pricing .price .item .content-top h3 span.sign {
  font-size: 24px;
  font-weight: normal;
}

.amp-pricing .price .item .content-top p.month {
  font-size: 18px;
  font-weight: 700 !important;
  text-align: center;
}

.amp-pricing .price .item ul {
  list-style-type: none;
  margin-top: 25px;
  margin-bottom: 0;

}

.amp-pricing .price .item li.check::before {
  content: '\f00c';
  font-family: 'FontAwesome';
  color: #00FF22;
  position: absolute;
  left: -32px;
}

.amp-pricing .price .item:hover li.check::before {
  color: #030621;
}

.amp-pricing .price .item li.un::before {
  content: '\f00d';
  font-family: 'FontAwesome';
  color: #FF0000;
  position: absolute;
  left: -32px;
}

.amp-pricing .price .item li {
  font-size: 17px;
  margin-bottom: 10px;
  position: relative;
  color: #7B7B7B;
}

.amp-pricing .price .item:hover li {
  color: #030621;
}

.amp-pricing .button-area {
  text-align: center;
}

.amp-pricing .button-area .btn-see {
  width: unset !important;
  margin-top: 60px;
  position: relative;
}

.amp-pricing .button-area .btn-see i {
  margin-left: 10px;
}

#pricing-plan {
  display: none;
}

/* our-characterstics */

.our-characterstics {
  background-color: #F5F5F5;
  padding-top: 0px !important;
  margin-top: 80px;
}

.our-characterstics .chars .row-item {
  display: grid;
}

.our-characterstics .chars .item {
  padding: 0px 10px;
}

.our-characterstics .content-area {
  padding: 80px 0px;
}

.our-characterstics .chars {
  margin-top: 70px;
}

.our-characterstics .chars .item .desc h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  margin: 15px 0px;
  color: #030621;
}

.our-characterstics .chars .item .desc p {
  font-size: 18px;
  color: #7B7B7B;
}

/* Excellence */

.excellence .items-area {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  margin-top: 66px;
}

.excellence .items-area .item img {
  max-width: 160px;
}

/* ready-to-sell */

.ready-to-sell .item {
  background-image: url('../img/pricing/ready-to-sell-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.ready-to-sell .item .content-area {
  padding: 60px 0px;
}

.ready-to-sell .item .content-area p {
  color: #030621;
}

.ready-to-sell .item .content-area .button-area {
  margin-top: 40px;
}

/* inner-footer */

footer.inner {
  margin-top: 0px !important;
}

/* affiliate-program page */


/* title-area */

.title-area {
  background-image: url('../img/affiliate/title-area-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 101px;

}

.title-area .content-area {
  position: relative;
  padding: 80px 0px 110px 0px;
}

.title-area .content-area h1 {
  font-size: 40px;
}

.title-area .content-area h1 span {
  font-weight: 300;
}

.title-area .content-area .content p {
  color: #030621;
  margin-top: 17px;
  font-size: 20px;
  line-height: 30px;
}

.title-area .content-area .button-area .btn-partner {
  width: unset !important;
  margin-top: 42px;
}

.title-area .content-area .image-area img {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
}

/* how-to */

.how-to .items-area {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 90px;
}

.how-to .items-area .item {
  text-align: center;
  padding: 0px 30px;
}

.how-to .items-area .item .content {
  margin-top: 20px;
}

.how-to .items-area .item .content h3 {
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 15px;
}

.how-to .items-area .item .content p {
  width: 63%;
  text-align: center;
  margin: 0 auto;
}

.how-to .button-area {
  margin-top: 50px;
}

/* who-can-partner */

.who-can-partner .partner-area {
  background-color: #030621;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  padding: 20px;
}

.who-can-partner .partner-area .content {
  padding-left: 60px;
}

.who-can-partner .partner-area .content h2 {
  color: #fff;
}

.who-can-partner .partner-area .content p {
  color: #C1C1C1;
}

/*why-popular  */

.why-popular {
  background-color: #F5F5F5;
  padding-top: 0px !important;
  margin-top: 80px;
}

.why-popular .content-area {
  padding: 80px 0px;
}

.why-popular .popular-area {
  margin-top: 40px;
}

.why-popular .popular-area .row-item {
  display: grid;
}

.why-popular .popular-area .item {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 30px 20px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  margin-top: 40px;
}

.why-popular .popular-area .item p {
  text-align: left;
  font-size: 20px;
  color: #030621;
  line-height: 30px;
}

.why-popular .popular-area .item img {
  margin-right: 15px;
}

.why-popular .popular-area .button-area {
  margin-top: 50px;
}

/* privacy-policy page */


.privacy-policy p.date {
  color: #030621;
}

.privacy-policy h3 {
  font-size: 28px;
  font-weight: 700;
  margin: 40px 0px 30px 0px;
}


.privacy-policy h4 {
  font-size: 22px;
  font-weight: 700;
  margin: 10px 0px;
}

.privacy-policy p a {
  color: #56F444;
  font-weight: 500;
}


/* contact-us-page */

.title-area.contact-title .content-area {
  position: relative;
  padding: 120px 0px 160px 0px;
}

.connect-and-contact .content ul.connect {
  list-style: none;
  padding: 0;
  margin-top: 40px;
}

.connect-and-contact .content ul.connect li {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

}

.connect-and-contact .content ul.connect li.phone::before {
  content: '\f095';
  font-family: 'fontAwesome';
  color: #56F444;
  width: 50px;
  height: 50px;
  border: 2px solid #56F444;
  padding: 5px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}


.connect-and-contact .content ul.connect li.mail::before {
  content: '\f0e0';
  font-family: 'fontAwesome';
  color: #56F444;
  width: 50px;
  height: 50px;
  border: 2px solid #56F444;
  padding: 5px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;

}

.connect-and-contact .content ul.connect li.location::before {
  content: '\f3c5';
  font-family: 'fontAwesome';
  color: #56F444;
  width: 50px;
  height: 50px;
  border: 2px solid #56F444;
  padding: 5px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;

}

.connect-and-contact .form-area .upper {
  margin-bottom: 30px;
}

.connect-and-contact .form-area .upper h3 {
  font-weight: 700;
}

.connect-and-contact .form-area {
  background-color: #F5F5F5;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.16);
  position: relative;
  z-index: 11;
  padding: 65px 45px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.connect-and-contact .form-area .form-control {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 22px 25px;
  margin-bottom: 20px;
  border: none;
}

.connect-and-contact .form-area .form-control:focus {
  box-shadow: none;
}

.connect-and-contact .form-area textarea {
  height: 200px;
}

.connect-and-contact .form-area .button-area {
  margin-top: 40px;
}

/* blog-page */


.home-blogs .search-area .form-control {
  background-color: #F5F5F5;
  border: none;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 23px 30px;
}

.search-area-wrapper {

  display: flex;
  justify-content: center;
  align-items: center;

}


.blog-nav-container {
  overflow-y: hidden !important;
  width: 870px !important;
  overflow-x: scroll !important;
}

.search-bar {
  width: 300px;
  margin-right: 20px;
}

.blog-nav-container::-webkit-scrollbar {
  display: none;
}

.nav-tabs {
  margin-left: 40px;
}

.nav-tabs::-webkit-scrollbar {
  display: none;
}

.home-blogs .search-area .form-control:focus {
  box-shadow: none;
}


.home-blogs .search-area .nav-link {
  color: #030621;
}

.nav-tabs {
  border-bottom: unset;
  gap: 5px;
}

.home-blogs .search-area .nav-link.active {
  border: none;
  background-color: #030621;
  color: #fff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 12px 20px;
}

.home-blogs .search-area .nav-link:hover {
  border: none !important;
}

.nav-tabs .nav-link {
  margin-bottom: 0px;
  background: 0 0;
  border: none;
}

.home-blogs .tab-content {
  margin-top: 60px;
}

.home-blogs .tab-content .item {
  margin-bottom: 60px;
}

.home-blogs .tab-content .item img {
  width: 100%;
}

.home-blogs .tab-content .item p.date {
  color: #56F444;
}

.home-blogs .tab-content .item h3 {
  font-size: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.home-blogs .tab-content .item p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 80%;
}

.how-leadbud-works .steps .step-1 img {
  width: 320px;
}

.how-leadbud-works .mobile-steps {
  display: none;
}

.how-leadbud-works .mobile-steps .step h4 {
  font-size: 22px;
  font-weight: 700;
}


/* data to revenue */

.data-to-revenue .image-area img {
  width: 70%;
  float: right;
}

/* table */

table {
  max-width: 1320px;
  margin-top: 60px;
  white-space: nowrap;
}

table th {
  font-size: 22px;
  font-weight: 500;
  color: #000;
}

table td i.fa-xmark {
  color: #F60B0B;
}

table tr.first {
  margin-bottom: 35px;
}

table td i.fa-check {
  color: #56F444;
}

table th.compare {
  font-weight: 700;
  font-size: 30px;
}

table td {
  padding: 20px 0px;
}

table td span {
  font-size: 17px;
  font-weight: 400;
  color: #7B7B7B;
}

table tr.bg {
  background-color: #F5F5F5;
  border-radius: 10px;
}

table td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-left: 10px;
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

table td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

table td.email {
  font-size: 22px;
  font-weight: bold;
  color: #000;
}

table td.email img {
  margin-right: 10px;
}

.s {
  border: none;
  margin-right: 10px;
}