.submenu-wrapper {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(195, 251, 216, 1);
    display: none;
    /* background-blend-mode: lighten; */
    /* height: 100px; */
    padding-top: 50px;
    padding-bottom: 50px;
    transform: scaleY(0);
    transition: all 0.2s;
    transform-origin: top;
    z-index: -1;
}

.show-dropdown-menu {
    display: block;
    transform-origin: top;
    transform: scaleY(1);
}


.platform-wrapper {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 50px;
    justify-content: space-between;
}


.pages-wrapper,
.teams-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 50px;
    justify-content: space-between;
}


.resources-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 50px;
    justify-content: space-between;
}

.links-without-heading>* {
    width: calc(100%/2);
    flex-shrink: 0;
    margin-bottom: 20px;
}


.link-image-column>h3,
.link-column>h3 {
    font-size: 20px !important;
    font-weight: 700;
    margin-bottom: 20px;
}