.list-bar-wraper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.list-bar {
    width: fit-content;
    border-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    align-items: center;
    gap: 25px;
}

@media screen and (max-width:900px) {
    .list-bar {
        box-shadow: none !important;
        border-radius: 0;
        flex-wrap: wrap;
        justify-content: center;
    }
}