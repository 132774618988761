@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');



body {
    font-size: 15px;
    line-height: 22.5px;
    background-color: #fff;
    color: #7B7B7B;
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden !important;
    font-weight: 400 !important;
}

h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 40px;
    color: #030621;
}

h3,
h4,
h5 {
    font-family: 'Poppins', sans-serif;
    color: #030621;
}

h2 {
    font-family: 'Poppins', sans-serif;
    color: #030621;
    font-weight: 700;
}

p,
ul {
    margin: 0px;
    margin-bottom: 20px;
}

a,
a:hover,
a:visited,
a:active,
a:focus {
    color: inherit;
    text-decoration: none;
}

.btn {
    font-family: 'Poppins', sans-serif;
    background: #030621;
    border: 2px solid #030621;
    font-size: 15px;
    /* color: #fff !important; */
    text-align: center;
    padding: 8px 20px;
    font-weight: 500 !important;
    transition: 0.3s ease-in-out;
    position: relative;
    border-radius: 10px;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}



.btn-second {
    font-family: 'Poppins', sans-serif;
    background: #fff;
    font-size: 16px;
    border: 2px solid #030621;
    color: #000 !important;
    text-align: center;
    padding: 15px 20px;
    font-weight: 500 !important;
    width: 230px !important;
    transition: 0.3s ease-in-out;
    border-radius: 10px;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.btn:hover {
    background-color: #fff;
    /* color: #030621 !important; */
    border: 2px solid #030621;
}

.btn:focus {
    outline: none;
    background: #030621;
    color: #fff !important;
}

.section {
    padding-top: 80px;
    text-align: left;
}

.section h2 {
    font-family: 'Poppins', sans-serif;
    color: #000;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 30px;
    color: #000;
}

.section h3 {
    font-size: 26px;
    color: #000;
    font-weight: 500;
    margin-bottom: 16px !important;
}

.section h2:first-child {
    margin-top: 0px;
    margin-bottom: 20px;
    color: #000;
}

.section p:last-child,
.section ul:last-child {
    margin-bottom: 0px;
}


.section-padding {
    padding: 0px 140px;
}

.no-padding {
    padding: 0px;
}

.no-margin {
    margin: 0px;
}

.hover-bg:hover {
    background-color: #f8f9fa;
    transition: background-color 0.2s ease;
  }
@keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  