.free-servise {
    background-size: contain !important;
    position: relative;

}


.free-servise::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(to right,
            rgba(5, 42, 0, 1) 50%,
            /* Start with the first color and end at the center */
            #030b1b 50%
            /* Start the second color from the center */
        )
}

.free-service-btn {
    background-color: #4bf879 !important;
    color: #000 !important;
    position: relative;
    z-index: 100;
    border-color: #4bf879 !important;
}

.free-service-btn:hover {
    background-color: #000 !important;
    color: #4bf879 !important;
    border-color: #000;
}

.free-services-fluid-images-wrapper {
    padding: 95px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99;
}

.free-services-fluid-images-wrapper>:nth-child(1) {
    display: flex;
    justify-content: center;
}

.free-services-fluid-images-wrapper>:nth-child(1)>img {
    margin-right: -180px;


}

.ai-image {
    width: 130px;
}

.icon-image {
    width: 50px;
}

.free-services-fluid-images-wrapper>:nth-child(2) {
    display: flex;
    justify-content: space-around;
    margin-top: 36px;
}


.free-services-fluid-images-wrapper>:nth-child(3) {
    margin-top: 50px;
}


.free-services-fluid-images-wrapper>:nth-child(3)>div {
    width: 500px;
    display: flex;
    justify-content: center;
}



.free-services-fluid-images-wrapper>:nth-child(4) {
    margin-top: 67px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}



.free-services-fluid-images-wrapper>:nth-child(4)>div {
    width: 44%;
    display: flex;
    margin-right: 300px;
    flex-direction: row-reverse;
    justify-content: space-between;
}


@media screen and (max-width:1400px) {
    .free-services-fluid-images-wrapper>:nth-child(3)>div {

        justify-content: start;
    }


    .free-services-fluid-images-wrapper>:nth-child(2) {
        justify-content: space-between;
    }



}

@media screen and (max-width:992px) {
    .free-services-fluid-images-wrapper {
        padding: 50px !important;
    }

    .free-services-fluid-images-wrapper>:nth-child(3)>div {
        width: 100%;
    }

    .free-services-fluid-images-wrapper>:nth-child(4)>div {
        width: 100%;
        margin-right: 0 !important;
    }

    .free-servise {
        background-size: 655% !important;
    }

}


@media screen and (max-width:686px) {
    .free-services-fluid-images-wrapper {
        padding: 25px !important;
    }
}

@media screen and (max-width:686px) {
    .free-services-fluid-images-wrapper {
        padding: 10px !important;
    }

    .ai-image {
        width: 80px !important;
    }

    .icon-image {
        width: 25px !important;
    }

    .free-services-fluid-images-wrapper>:nth-child(3)>div {
        margin-top: 66px !important;
    }
}


/* .free-services-fluid-images-wrapper>div>img {
    width: 130px;
}

.free-services-fluid-images-wrapper>img {
    width: 100px;
    position: absolute;
}




.free-services-fluid-images-wrapper>:nth-child(2) {
    right: 68%;
    width: 130px;
    top: 50%;
}

.free-services-fluid-images-wrapper>:nth-child(3) {
    right: 27%;
    bottom: 19%;
    width: 130px;
}

.free-services-fluid-images-wrapper>:nth-child(4) {
    right: 68%;
    width: 50px;
    top: 28%;
}

.free-services-fluid-images-wrapper>:nth-child(5) {
    width: 50px;
    bottom: 17%;
    left: 41%;
}

.free-services-fluid-images-wrapper>:nth-child(6) {
    width: 50px;
    right: 30%;
    top: 32%;
} */