@media only screen and (max-width: 1880px) {

    .why-popular .popular-area .item {
        padding: 40px 30px;
    }
}


@media only screen and (max-width: 1800px) {

    .home-banner .content-area .content h1 span br {
        display: none;
    }

}

@media only screen and (max-width: 1755px) {}


@media only screen and (max-width: 1683px) {}

@media only screen and (max-width: 1637px) {}


@media only screen and (max-width: 1650px) {
    .navbar-nav>li {
        padding: 20px 20px;
    }
}


@media only screen and (max-width: 1600px) {
    .home-testimonials .owl-carousel .item .user-area .info p.name {
        font-size: 21px;
    }

    .home-blogs .tab-content .item h3 {
        font-size: 25px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .home-blogs .tab-content .item p {
        width: 100%;
    }

    .connect-and-contact .form-area {
        padding: 65px 40px;
    }

    .title-area.contact-title .content-area {
        padding: 190px 0px 220px 0px;
    }

    .why-popular .popular-area .item {
        padding: 40px 20px;
    }

    .why-popular .popular-area .item p {
        font-size: 20px;

    }

    .who-can-partner .partner-area .content {
        padding-left: 35px;
    }

    .how-to .items-area .item {
        padding: 0px 0px;
    }

    .how-to .items-area .item .content p {
        width: 90%;
    }


    .title-area .content-area h1 {
        font-size: 40px;
    }

    .ready-to-sell .item .content-area {
        padding: 90px 0px;
    }

    .our-characterstics .chars .item .desc h3 {
        font-size: 26px;
        line-height: 35px;
        margin: 15px 0px;
    }

    .amp-pricing .price .item .button-area .magnet-button {
        margin: 30px 0px;
        padding: 12px 30px;
    }

    .amp-pricing .price .item ul {
        list-style-type: none;
        margin-top: 25px;
    }

    .amp-pricing .price .item {
        padding: 50px 20px;
    }

    .amp-pricing .price .item p {
        font-size: 18px;
    }


    .pricing-title-area .content-area .image-area img.hand {
        right: 0%;
        top: 0%;
    }

    .pricing-title-area .content-area {
        padding: 100px 0px;
    }

    .pricing-title-area .content-area h1 {
        font-size: 50px;
    }

    .login-signup .login-signup-area .content-area p {
        font-size: 25px;
        line-height: 36px;
    }

    .padding-left {
        padding-left: 100px !important;
    }

    .home-platform-capabilities .content-area ul li {
        padding: 20px 20px;
        width: 98%;
        position: relative;
        align-items: center;
    }






    header .header-padding {
        padding: 0px 100px;
    }

    .section-padding {
        padding: 0px 100px !important;
    }

    header .main-nav {
        padding-left: 0px;
    }

    .navbar-nav>li {
        padding: 20px 15px;
    }

    header.sticky .navbar-nav>li {
        padding: 20px 15px;
    }



    .home-banner .content-area {
        padding: 120px 0px;
    }




    .home-banner .content-area .image-area img {
        margin-bottom: -116px;
        margin-left: 30px;
    }

    .home-banner .content-area .image-area .icon.slack {
        right: 19%;
        top: 33%;
    }

    .home-banner .content-area .image-area .icon.whatsapp {
        right: 12%;
        top: 47%;
    }

    .home-banner .content-area .image-area .icon.pin {
        right: 15%;
        top: 62%;
    }

    .home-banner .content-area .image-area .icon.redit {
        right: 12%;
        top: 75%;
    }
}



@media only screen and (max-width: 1550px) {


    .navbar-nav>li>a {
        font-size: 16px;
    }

    header ul.nav-right a {
        font-size: 16px;
    }


    .faq-para-wrapper {
        padding: 0 291px !important;
    }
}


@media only screen and (max-width: 1484px) {}


@media only screen and (max-width: 1400px) {


    .search-area-wrapper {
        flex-direction: column;
    }

    .search-bar {
        margin-top: 20px;
        width: 100%;
        order: 2;
    }

    .blog-nav-container {
        order: 1;
        margin-top: 30px !important;
    }

    .nav-tabs {
        margin-top: 0px !important;
        margin-left: 0 !important;
    }

    .faq-para-wrapper {
        padding: 0 161px !important;
    }

    .pricing-title-area .content-area.blog {
        padding: 80px 0px;
    }

    .section h3 {
        font-size: 24px !important;
    }

    footer .main-footer .lower-footer h4 {
        font-size: 20px;
    }

    .title-area.contact-title .content-area {
        padding: 145px 0px 220px 0px;
    }

    .title-area .content-area .image-area img {
        z-index: 11;
        width: 50%;
    }

    .title-area .content-area .content {
        position: relative;
        z-index: 99;
    }

    .title-area .content-area {
        padding: 80px 0px;
    }

    .ready-to-sell .item .content-area {
        padding: 70px 0px;
    }

    .amp-pricing .price .item .content-top p.tag {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .amp-pricing .price .item .content-top h3 {
        font-size: 33px;
    }

    .pricing-title-area .content-area .image-area img.hand {
        right: 0%;
        top: -6%;
    }

    .pricing-title-area .content-area h1 {
        font-size: 45px;
    }

    .pricing-title-area .content-area {
        padding: 80px 0px;
    }

    .login-signup .login-signup-area .content-area p {
        font-size: 22px;
        line-height: 30px;
    }



    .home-banner .content-area p {
        font-size: 23px;
    }



    .home-testimonials .owl-carousel .item {
        padding: 30px 20px;
        margin-right: 20px;
    }

    .padding-left {
        padding-left: 50px !important;
    }

    .home-brands .items-area {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
    }

    header .header-padding {
        padding: 0px 50px;
    }

    .section-padding {
        padding: 0px 50px !important;
    }


    .home-features .content p {
        font-size: 20px;
        line-height: 30px;
    }

    .home-features .content .features-area ul li {
        font-size: 16px;
        margin-top: 20px;
    }


    .home-brands .items-area .item img {
        width: 220px;
    }

    .home-testimonials .owl-carousel .item .user-area .info p.name {
        font-size: 20px;
    }

    .home-testimonials .owl-carousel .item .user-area .info p.company {
        font-size: 16px;
    }
}


@media only screen and (max-width: 1320px) {
    table {
        width: 1320px;
        white-space: nowrap;
    }
}



@media only screen and (max-width: 1300px) {

    .why-popular .popular-area .item p {

        text-align: center;
        margin-top: 20px;
    }

    .home-banner .content-area p {
        font-size: 20px;
    }



    .more-saas-tool .content-area .items .item p {
        font-size: 20px;
    }

    .why-popular .popular-area .item {
        padding: 40px 20px;
        flex-direction: column;
    }


    .title-area .content-area h1 {
        font-size: 45px;
    }


    footer .main-footer .upper-footer .links ul li {
        padding: 0px 18px;
    }

    .pricing-title-area .content-area .image-area img.hand {
        right: -2%;
        top: -8%;
    }

    .pricing-title-area .content-area .image-area img.dollar {
        left: 0%;
        bottom: 0;
    }

    .amp-pricing .price .item {
        padding: 38px 15px;
    }

    .amp-pricing .price .item .content-top h3 {
        font-size: 35px;
    }
}

@media only screen and (max-width: 1250px) {
    .copyright-icons {
        flex-direction: column;
    }

    .copyright-icons>* {
        text-align: center !important;
    }

    .copyright-icons:nth-child(2) {
        margin-top: 15px !important;
    }

    .home-banner .content-area .image-area .anim .main {
        display: none;
    }

    .home-banner .content-area .image-area .anim .mobile {
        display: block;
    }



    .home-banner .content-area .image-area .icon.fb {
        display: none;
    }


    .home-banner .content-area .image-area .icon.insta {
        display: none;
    }

    .home-banner .content-area .image-area .icon.twitter {
        display: none;
    }

    .home-banner .content-area .image-area .icon.linkedin {
        display: none;
    }

    .home-banner .content-area .image-area .icon.slack {
        display: none;
    }

    .home-banner .content-area .image-area .icon.whatsapp {
        display: none;
    }

    .home-banner .content-area .image-area .icon.pin {
        display: none;
    }

    .home-banner .content-area .image-area .icon.redit {
        display: none;
    }
}


@media only screen and (max-width: 1200px) {


    .faq-para-wrapper {
        padding: 0 89px !important;
    }

    .footer-links {
        width: 30% !important;
    }

    .navbar-brand>img {
        width: 140px !important;
    }

    .navbar-nav>.header-btn {
        width: 150px !important;
    }

    .pricing-title-area .content-area.blog h1 {
        font-size: 40px;
    }

    table th.compare {
        font-weight: 700;
        font-size: 22px;
    }

    table td.email {
        font-size: 20px;
    }


    .section h3 {
        font-size: 22px !important;
    }

    .more-saas-tool .content-area .items .item {
        padding: 30px 20px;
    }

    .home-blogs .tab-content .item h3 {
        font-size: 22px;
    }

    .connect-and-contact .form-area {
        padding: 50px 30px;
    }

    .title-area.contact-title .content-area {
        padding: 120px 0px 220px 0px;
    }




    .title-area .content-area h1 {
        font-size: 35px;
    }

    .our-characterstics .chars .item .desc h3 {
        font-size: 22px;
        line-height: 29px;
    }

    .our-characterstics .chars .item {
        padding: 0px 0px;
    }

    .price {
        margin-top: 50px;
    }

    .amp-pricing .price .item {
        margin-top: 30px;
    }

    .amp-pricing .price .item .content-top h3 {
        font-size: 30px;
    }

    .amp-pricing .price .item p {
        font-size: 17px;
    }

    .amp-pricing .price .item li {
        font-size: 18px;
        margin-bottom: 15px;
    }

    .pricing-title-area .content-area .image-area img.hand {
        right: 0%;
        top: -16%;
    }

    .pricing-title-area .content-area .image-area img.dollar {
        left: 0%;
        bottom: -20%;
    }

    .pricing-title-area .content-area h1 {
        font-size: 40px;
    }


    .pricing-title-area .content-area p {
        font-size: 22px;
    }

    .home-banner {
        background-position: inherit;
    }



    .padding-left {
        padding-left: 30px !important;
    }



    .home-features .content .features-area ul li {
        width: 100%;
    }

    header .header-padding {
        padding: 0px 30px;
    }

    .section-padding {
        padding: 0px 30px;
    }

    header ul.nav-right .btn-sign-up {
        display: none;
    }


    footer {
        margin-top: 70px;
    }

    footer .main-footer .upper-footer {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    footer .main-footer .upper-footer .links {
        margin: 30px 0px;
    }

    footer .main-footer .lower-footer {
        text-align: center;
    }

    footer .copyright .content {
        flex-direction: column;
    }

    footer .copyright .content .right {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 1160px) {}

@media only screen and (max-width: 1100px) {
    .excellence .items-area {
        flex-wrap: wrap;
    }

    .excellence .items-area .item {
        margin-top: 25px;
    }
}


@media only screen and (max-width: 1050px) {}


@media only screen and (max-width: 992px) {


    .contact-title {
        padding-top: 170px !important;
    }

    .amp-pricing .price .item li {
        font-size: 15px;
    }

    .amp-pricing .price .item p {
        font-size: 15px !important;
    }

    table td span {
        font-size: 15px !important;
    }

    table td {
        font-size: 15px !important;
        padding: 15px 10px !important;
    }

    table th {
        font-size: 20px !important;
    }

    table th.compare {
        font-size: 20px !important;
    }

    .connect-and-contact .content ul.connect li.location::before {
        margin-right: 0px !important;
    }

    .faq-para-wrapper {
        padding: 0 0px !important;
    }

    .pricing-title-area {
        padding-top: 75px;
    }

    table br {
        display: block;
    }

    .pricing-title-area .content-area.blog h1 {
        font-size: 35px;
    }

    .amp-pricing .price .item .content-top p {
        font-size: 17px;
    }

    .home-testimonials .owl-carousel {
        margin-top: 30px;
    }

    footer {
        margin-top: 65px;
    }

    .section h2 {
        font-size: 30px !important;
    }

    .section {
        padding-top: 65px !important;
    }

    .home-banner .content-area .image-area img {
        margin-bottom: -116px;
        margin-left: 0px;
    }

    br {
        display: none;
    }

    .home-platform-capabilities {
        margin-top: 60px;
    }

    .how-leadbud-works .steps img {
        display: none;
    }

    .how-leadbud-works .mobile-steps {
        margin-top: 60px !important;
        display: block !important;
    }

    .how-leadbud-works .mobile-steps .step img {
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        width: 45%;
        margin-top: -100px;
    }

    .nav-tabs {
        border-bottom: unset;
        gap: 20px;
        margin-top: 40px;
        justify-content: center;
    }

    .connect-and-contact .content ul.connect li {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        flex-direction: column;
    }

    .connect-and-contact .content ul.connect li::before {
        margin-right: 0 !important;
        margin-bottom: 10px !important;
    }

    .connect-and-contact .form-area {
        padding: 50px 15px;
        margin-top: 50px;
    }

    .title-area.contact-title .content-area {
        padding: 100px 0px;
        padding-top: 78px;
    }

    .why-popular .popular-area {
        margin-top: 25px;
    }

    .why-popular .content-area {
        padding: 70px 0px;
    }

    .why-popular .popular-area .item {
        padding: 30px 15px;
        flex-direction: column;
    }

    .who-can-partner .partner-area .content p {
        color: #C1C1C1;
        font-size: 18px;
    }

    .who-can-partner .partner-area .content {
        padding: 20px 0px;
        margin-bottom: 40px;
    }

    .title-area .content-area {
        padding: 60px 0px;
    }

    .title-area {
        padding-top: 78px;
    }

    .title-area .content-area .content {
        text-align: center;
    }

    .title-area .content-area h1 {
        font-size: 35px;
    }

    .title-area .content-area .image-area {
        text-align: center;
    }

    .title-area .content-area .image-area img {
        display: none;
    }

    .contact-img img {
        display: block !important;
        width: 40% !important;
        z-index: 11 !important;
        top: -130px !important;
        left: 28% !important;
    }

    .ready-to-sell .item .content-area {
        padding: 50px 15px;
    }

    .ready-to-sell .item .content-area p br {
        display: none;
    }

    .excellence .items-area .item {
        width: 150px;
    }

    .our-characterstics .chars {
        margin-top: 40px;
    }

    .our-characterstics {
        margin-top: 60px;
    }

    .our-characterstics .content-area {
        padding: 70px 0px;
    }

    .our-characterstics .chars .item {
        margin-top: 30px;
    }

    .pricing-title-area .content-area h1 {
        font-size: 40px;
    }

    .pricing-title-area .content-area h2 {
        font-size: 22px;
    }

    .pricing-title-area .content-area p {
        font-size: 20px;
    }

    .pricing-title-area .content-area .image-area img.hand {
        right: 0;
        top: -19%;
    }

    .pricing-title-area .content-area .image-area img.dollar {
        left: 0%;
        bottom: -13%;
    }

    .how-leadbud-workd .content-area p br {
        display: none;
    }

    footer .main-footer .lower-footer {
        padding: 30px 0px;
    }

    .home-testimonials .owl-carousel .owl-nav {
        display: none;
    }

    .home-testimonials .owl-carousel .item {
        padding: 30px 15px;
        margin: 0px 10px;
    }

    .padding-left {
        padding-left: 0px !important;
    }

    .home-faqs .content-area p br {
        display: none;
    }

    .home-faqs .content {
        padding: 70px 0px;
    }

    .home-faqs .faqs-area .accordion .accordion-item .accordion-body {
        text-align: left;
    }


    .data-to-revenue .image-area {
        text-align: center;
        margin-top: 40px;
    }

    .api-integrations .content-area {
        padding: 70px 0px;
    }

    .home-platform-capabilities .content-area .image-area img {
        margin-top: 40px;
    }

    .float-end {
        float: unset !important;
    }

    .api-integrations .content-area .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }

    .more-saas-tool .content-area {
        padding: 70px 0px;
    }

    .home-platform-capabilities .content-area {
        padding: 70px 0px;
    }

    .home-platform-capabilities .content-area ul li {
        padding: 20px 15px;
        width: 100%;
        position: relative;
    }

    .more-saas-tool .content-area .items .item {
        background-color: #fff;
        padding: 30px 15px;
        margin-top: 30px;
    }


    .home-features .content .features-area ul {
        display: inline-block;
    }

    .home-banner .content-area .items {
        display: inline-block;
        gap: 20px;
    }

    .more-saas-tool {
        margin-top: 60px;
    }

    .home-features .image-area {
        text-align: center;
        margin-bottom: 60px;
    }



    .home-banner {
        padding-top: 78px;
        text-align: center;
    }

    .home-banner .content-area .items {
        justify-content: center;
    }

    header ul.nav-right {
        margin-bottom: 0;
        display: grid !important;
        align-items: center;
        gap: 0px;
        justify-content: flex-start;
        padding-left: 10px;
    }

    header ul.nav-right a {
        font-weight: 500;
        font-size: 18px;
    }

    header ul.nav-right .sales::before {
        display: none;
    }

    header ul.nav-right .login::before {
        display: none;
    }

    header .header-padding {
        padding: 0px 15px;
    }

    .section-padding {
        padding: 0px 15px;
    }

    header ul.nav-right .sales {
        margin-bottom: 10px;
    }

    header .navbar-brand img {
        width: 200px;
    }



    .home-banner {
        padding-top: 50px;
        text-align: center;
    }

    .home-banner .content-area {
        padding: 70px 0px;
    }

    .owl-carousel .owl-stage {
        margin: 20px 0px;
    }

    .home-faqs .faqs-area .accordion-item .accordion-button::after {
        font-size: 18px;
    }


    .data-to-revenue .image-area img {
        width: 100%;
        float: unset;
    }

    .why-popular .popular-area .item p {
        font-size: 19px;
    }
}

@media screen and (max-width:990px) {
    .navbar-brand>img {
        width: 180px !important;
    }
}


@media screen and (max-width:'930px') {
    .accordion-button {
        font-size: 17px !important;
    }


}



@media only screen and (max-width: 768px) {

    .pricing-title-area .content-area h1 {
        font-size: 30px !important;
    }


    .our-characterstics .chars .item .desc p {
        font-size: 15px !important;
    }

    .connect-and-contact .form-area .form-control {
        padding: 15px 10px !important;
    }

    .excellence .items-area .item {
        width: 100px !important;
        flex-shrink: 0;
    }



    .excellence .items-area .item img {
        width: 100% !important;
        height: auto !important;
    }

    .section h3 {
        font-size: 18px !important;
    }

    .who-can-partner .partner-area .content p {
        font-size: 16px !important;
    }

    .home-features .content p {
        font-size: 15px !important;
        line-height: 20px !important;
    }

    .how-leadbud-works .mobile-steps .step img {
        width: 35% !important;
    }


    .more-saas-tool .content-area .items .button-area a img {
        max-width: 250px !important;
    }

    body {
        font-size: 12px !important;
        line-height: 18px !important;
    }

    .section h2 {
        font-size: 27px !important;
    }

    .title-area .content-area,
    .home-platform-capabilities .content-area,
    .home-banner .content-area,
    .more-saas-tool .content-area,
    .home-platform-capabilities .content-area,
    .api-integrations .content-area {
        padding: 0 !important;
        padding-bottom: 70px !important;
    }

    .home-banner .content-area {
        padding-top: 10px !important;
    }

    .home-faqs .content {
        padding: 0 !important;
        padding-bottom: 40px !important;
    }

    .footer-links {
        width: 45% !important;
    }

    .pricing-title-area .content-area p {
        font-size: 17px;
        line-height: 30px;
    }

    .pricing-title-area .content-area h1 {
        font-size: 35px;
    }

    .pricing-title-area .content-area h2 {
        font-size: 20px;
    }

    .how-leadbud-works .mobile-steps .step img {
        margin-top: -50px;
    }

    .title-area.contact-title .content-area {
        padding: 80px 0px;
    }

    .how-to .items-area {
        margin-top: 30px;
    }

    .how-to .items-area .item {
        margin-top: 30px;
    }

    .how-to .items-area {
        margin-top: 50px;
        flex-direction: column;
    }

    .pricing-title-area .content-area .image-area img.hand {
        right: 0;
        top: -7%;
        width: 20%;
    }

    .pricing-title-area .content-area h1 {
        font-size: 35px;
    }

    .pricing-title-area .content-area .image-area img.dollar {
        left: 0%;
        bottom: 0%;
        width: 20%;
    }

    .login-signup .login-signup-area {
        width: 100%;
    }

    .login-signup .bg-with-image {
        display: none;
    }


    .home-testimonials .owl-carousel .item .user-area {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .home-testimonials .owl-carousel .item i.quote {
        position: unset !important;
    }

    .home-testimonials .owl-carousel .item .user-area .info p.name {
        font-size: 22px;
        margin-top: 10px;
    }

    .home-testimonials .owl-carousel .item .user-area img {
        margin-top: 10px;
    }

    .home-banner .content-area h1 span {
        font-size: 27px;
    }

    .home-banner .content-area h1 {
        font-size: 27px;
    }

    .data-to-revenue .image-area img {
        width: 50% !important;
    }

}


@media screen and (max-width:650px) {

    .section h2 {
        font-size: 20px !important;
    }

    .home-banner .content-area h1 span {
        font-size: 22px;
    }

    .home-banner .content-area h1 {
        font-size: 22px;
    }

    .footer-images {
        align-items: center !important;
        justify-content: center !important;
    }
}


@media only screen and (max-width: 600px) {
    .pricing-title-area .content-area.blog h1 {
        font-size: 27px;
    }

    .title-area .content-area h1 {
        font-size: 27px;
    }

    .home-platform-capabilities .content-area ul li::after {
        display: none;
    }



    .home-banner .content-area p {
        font-size: 20px;
    }

    .section h2 {
        font-size: 28px;
    }

}

@media screen and (max-width:574px) {

    .amp-pricing .row-item {
        width: 320px !important;
    }

    .home-banner .content-area h1 {
        font-size: 20px !important;
    }

    .home-banner .content-area h1 span {
        font-size: 20px !important;
    }

    .home-banner .content-area p {
        font-size: 16px !important;
    }

    .how-leadbud-works .mobile-steps .step img {
        margin-top: -30px !important;
        width: 45% !important;
    }

    .footer-links {
        width: 100% !important;
    }

    .copyright-icons {
        justify-content: center;
        align-items: center;
    }

    .data-to-revenue .image-area img {
        width: 50% !important;
    }

}


@media only screen and (max-width: 536px) {
    footer .copyright .content .right img {
        margin-bottom: 15px;
    }
}


@media only screen and (max-width: 500px) {

    .pricing-title-area .content-area h1 {
        font-size: 25px !important;
    }

    .privacy-policy h3 {
        font-size: 22px !important;
    }


    .privacy-policy h4 {
        font-size: 18px !important;
    }

    .pricing-title-area .content-area.blog h1 {
        font-size: 22px;
    }

    .title-area .content-area h1 {
        font-size: 22px;
    }

    .title-area .content-area .content p {
        font-size: 18px !important;
    }

    .how-leadbud-works .mobile-steps .step h4 {
        font-size: 20px;
    }

    .title-area.contact-title .content-area {
        padding: 60px 0px;
    }

    .why-popular .popular-area .item p {
        font-size: 18px;
    }

    .amp-pricing .switch-area .bill-label p {
        font-size: 20px;
        margin: 0px 5px;
    }

    footer .main-footer .upper-footer .links ul li {
        display: block;
        padding: 0px 0px !important;
        text-align: center;
    }

    .home-features .content .features-area ul li {
        font-size: 14px;
    }

    .home-brands .items-area .item img {
        width: 150px;
    }

    .home-faqs .faqs-area .accordion-item .accordion-button::after {
        font-size: 15px !important;
        margin-left: 5px !important;
    }

}


@media only screen and (max-width: 460px) {
    .accordion-button {
        font-size: 14px !important;
    }

    .accordion-body>p {
        font-size: 12px !important;
    }
}


@media only screen and (max-width: 430px) {
    .pricing-title-area .content-area .image-area img.hand {
        right: 0;
        top: 0%;
        width: 20%;
    }

    .pricing-title-area .content-area .image-area img.dollar {
        left: 0%;
        bottom: 0%;
        width: 20%;
    }

    .pricing-title-area {
        padding-top: 78px;
    }

    .section h2 {
        font-size: 26px;
    }
}


@media only screen and (max-width: 400px) {

    .login-signup p {
        font-size: 18px;
    }

    .login-signup .login-signup-area .content-area p {
        font-size: 18px;
    }

    .login-signup .login-signup-area .button-area .btn-second {
        font-size: 18px;
        padding: 15px 30px;
    }
}


@media only screen and (max-width: 380px) {}



@media only screen and (max-width: 360px) {}



@media (max-width: 992px) {
    .pricing-title-area .content-area.blog {
        padding: 60px 0px;
    }

    .login-signup .login-signup-area.login {
        margin-top: 30px;
    }

    .login-signup .login-signup-area .content-area p {
        font-size: 20px;
        line-height: 28px;
    }

    .login-signup .login-signup-area .content-area h1 {
        font-size: 28px;
    }

    .login-signup .login-signup-area {
        padding: 90px 0px 0px 0px;
    }

    footer .main-footer .lower-footer ul li a {
        font-size: 16px;
    }

    footer .copyright .content span {
        font-size: 16px;
    }

    /* style mobile nav */

    .section-padding {
        padding: 0px 15px;
    }

    .section {
        text-align: center !important;
    }


    header.sticky-mobile {
        background: #fff;
        box-shadow: 0px 2px 30px rgba(0 0 0 / 20%);
    }

    header .navbar-brand {
        padding: 0px;
    }

    header .navbar ul {
        margin-top: 10px;
        display: block;
    }

    header .navbar ul li {
        margin-top: 0px;
        padding: 0px 10px;
    }

    .main-nav .navbar-nav>li {
        padding: 0px 15px;
        padding: 4px 15px;
    }

    .navbar-nav .nav-item.phone {
        display: none;
    }

    .navbar-header {
        float: none;
    }

    .dropdown .icon:before {
        font-family: 'FontAwesome';
        content: "\f0fe";
        color: #000;
        font-style: initial;
        font-size: 26px;
    }

    .navbar-left,
    .navbar-right {
        float: none !important;
    }

    .navbar-toggle {
        display: block;
    }

    /* 
    .navbar-collapse {
        border-top: 1px solid #8B0A1C;
        background-color: #fff;
        margin-top: 8px;
    } */

    .navbar-nav .nav-item .nav-link {
        color: #000;
        font-size: 18px;
    }

    .main-nav .navbar-right {
        margin: 10px 0px;
        font-size: 14px;
    }

    .main-nav .navbar-nav>li>a {
        color: #fff;
    }

    .main-nav .navbar-nav .open .dropdown-menu>li>a {
        color: #000;
    }

    .main-nav .navbar-nav>.open>a,
    .main-nav .navbar-nav>.open>a:hover,
    .main-nav .navbar-nav>.open>a:focus {
        color: #fff;
        background-color: transparent;
    }

    .main-nav .navbar-collapse {
        border-color: #f0f0f0;
    }

    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }

    .navbar-nav {
        float: none !important;
        margin-top: 7.5px;
    }

    .navbar-nav>li {
        float: none;
    }

    .navbar-nav>li>a {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .collapse.in {
        display: block !important;
    }


    footer .main-footer .content-logo {
        text-align: center;
    }

    footer .main-footer .links-area {
        text-align: center;
    }

    footer .copyright-area .reach-logo {
        width: 150px;
        margin-top: 20px;
    }

    footer .copyright .content {
        display: grid;
        justify-content: center;
    }

    footer .copyright {
        text-align: center;
    }



    .navbar .nav-item .dropdown-menu {

        display: none;

    }

    .navbar .nav-item:hover .dropdown-menu {

        display: block;

    }

    .navbar .nav-item .dropdown-menu {
        margin-top: 5px;
    }


    .navbar-expand-xl .navbar-nav .dropdown-menu {

        border: none !important;

    }


    .dropdown-toggle::after {

        display: none;

    }


    .dropdown .icon {

        display: block;

        position: absolute;

        right: 15px;

        top: 14px;

    }


    .dropdown .icon:before {

        font-family: 'FontAwesome';

        content: "\f0fe";

        color: #000;

        font-style: initial;

        font-size: 26px;

    }

    .dropdown .open.icon:before {

        content: "\f146";

    }

    .dropdown-submenu .sub-menu {
        position: unset !important;
        right: unset !important;
        z-index: unset !important;
        top: unset !important;
    }
}