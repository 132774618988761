.nav-component-section {
    padding-top: 30px !important;
}


.nav-icon-bottom>img {
    width: 48px;
    height: 48px;
}

@media screen and (max-width:500px) {

    .nav-icon-bottom>img {

        width: 35px !important;
        height: 35px !important;

    }

    .nav-tab-bottom-wrapper {
        align-items: flex-start;
    }


    .nav-tab-bottom-wrapper>:nth-child(2)>h5 {
        font-size: 18px !important;
    }


    .nav-tab-bottom-wrapper>:nth-child(2)>p {
        font-size: 14px !important;
    }
}