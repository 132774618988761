.landing-page-input {
    width: 450px;
}

.landing-page-input>.btn {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}


.landing-page-input>input {
    width: calc(100% - 114px);
    padding: 10px 20px;
    border: none;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    outline: none;
}

.landing-page-inner>div {
    padding-bottom: 160px;
}

.google-ratting {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 15px;
}

.google-ratting>img {
    width: 25px;
    height: 25px;
    margin-right: 15px;
}

.google-ratting>p {
    margin-bottom: 0 !important;
    color: #7b7b7b;
    display: flex;
    align-items: center;
}

.landing-page-image-area>img {
    width: 90% !important;
}

.google-ratting>p>img {
    margin-right: 5px;
}


.landing-page-item>.items {
    gap: 20px !important;
    margin-top: 90px;
}

.tabs-image-wrapper>img {
    width: 600px;
}


.tabs-image-wrapper {
    height: 500px;
}


@media screen and (max-width:1200px) {
    .tabs-image-wrapper>img {
        width: 100% !important;
    }
}

@media screen and (max-width:992px) {
    .landing-page-image-area>img {
        width: 80% !important;
    }

    .tabs-image-wrapper>img {
        width: 100% !important;
    }

    .landing-page-input>input {
        width: 320px !important;

    }

    .landing-page-item>.items {
        gap: 20px !important;
        margin-top: 25px;
    }

    .landing-page-inner>div {
        padding-bottom: 147px;
    }

    .landing-page-item>.items>.item>p {
        margin-bottom: 0 !important;
    }


    .reach-your-ideas {
        flex-direction: column-reverse !important;
    }

}






@media screen and (max-width:560px) {
    .tabs-image-wrapper {
        height: auto !important;
    }

}






@media screen and (max-width:500px) {
    .image-area>img {
        width: 100% !important;
    }

    .landing-page-input>input {
        width: 150px !important;
        padding: 13px 20px;

    }


    .landing-page-input {
        width: auto !important;
    }

    .landing-page-input>input {
        flex: 1 !important;
    }


    .landing-page-item>.items {
        gap: 10px !important;
        margin-top: 20px !important;
        justify-content: space-around !important;
    }

    .landing-page-item>.items>.item>i {
        margin-right: 5px !important;
    }

    .landing-page-item>.items>.item>p {
        font-size: 13px !important;
    }

    .browse-wrapper>div>p {
        font-size: 16px !important;
    }

}