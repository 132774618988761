* {
    font-family: Poppins, sans-serif;
}

.bg-bgmain {
    background-image: url('../../assets/img/banner-bg.png');
}

.bg-landing-page {
    background-image: url('../../assets/img/landing-page/landing-page-bg.png');

}

.header-container {
    padding: 12px 0px;
    margin: auto;

}

.brand-links-wrapper {
    display: flex;
    align-items: center;
    /* position: relative; */
}

.nav-brand>a {
    display: inline-block;
    width: 177px;
}

.nav-brand-wrapper>img {
    width: 100%;
    height: auto;
}

.links-wrapper {
    display: flex;
    gap: 16px;
    margin-left: 40px;
}

ul.navbar-ul {
    padding: 0 !important;
    margin-bottom: 0px !important;
    /* margin: 0 !important; */
}

ul>li {
    list-style-type: none;
}

a {
    text-decoration: none !important;
}

.links-wrapper {
    display: flex;
    gap: 16px;
    margin-left: 40px !important;
}

.nav-item {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    padding: 12px 0px;
    /* font-size: 18px; */
    /* font-weight: 500; */
    color: #030621;
    position: static;
    overflow: hidden;
    /* Ensure no overflow from pseudo-element */
    border-bottom: 2px solid transparent;
    /* Initial transparent border for spacing */
    margin: 0;
    font-family: '_soehne_42618b', '_soehne_Fallback_42618b' !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    line-height: 140% !important;
    font-weight: 500 !important;
    text-decoration-thickness: 2px !important;
    text-underline-offset: 8px !important;

}

.nav-item>p {
    margin: 0;
    font-family: '_soehne_42618b', '_soehne_Fallback_42618b' !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    line-height: 140% !important;
    font-weight: 500 !important;
    text-decoration-thickness: 2px !important;
    text-underline-offset: 8px !important;

}

.nav-item:hover {
    border-bottom: 2px solid #030621;
}

.custom-dropdown-menu {
    position: absolute;
    background-color: #e9e9e9;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    border-radius: 0.375rem;
    padding: 2rem;
    padding-left: 5rem;
    padding-right: 5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding-top: 0px !important;
    width: 100%;
    left: 0;
    top: 45px;
    z-index: 30;
    margin: auto;
}

.dropDown-mobile-item {
    font-size: 15px !important;
    width: 100% !important;
    color: #000 !important;
    font-weight: 500 !important;
}

.dropDown-mobile-item:hover,
.dropDown-item:hover {
    transition: all 0.3s ease;
    background-color: #4ae84d2f !important;
    border-radius: 16px;
}





.custom-dropdown-menu>a {
    padding: .75rem;
}


.hidden {
    display: none;
}

.show {
    display: block;
}

.my-flex {
    display: flex;
}



.mobile-navbar-links-container {

    display: flex;
    flex-direction: column;

    padding: 2rem;
    /* Equivalent to p-3 */
    background-color: #53f655;
    /* Equivalent to bg-bgmain, define --bg-main in your CSS variables */
    overflow-y: scroll;
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */

    height: 92%;
    /* Equivalent to h-[95%] */
    width: 100%;
    /* Equivalent to w-[100%] */

    position: absolute;
    top: 51px;
    padding-top: 0px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    left: 0;
    z-index: 99;
}

.sticky-header {
    position: fixed !important;
    top: 0 !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 999 !important;
}

.mobile-nav-bar::-webkit-scrollbar {
    display: none;
}

.mobile-dropdown-menu-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1;
    transition: all 700ms ease-in-out;
    border-bottom: solid black 1px;
    padding-top: 10px;
    padding-bottom: 10px;
}


.header-btn-wrapper>:nth-child(1) {
    background-color: transparent !important;
    color: #000 !important;
}


.header-btn-wrapper>:nth-child(1):hover {
    background-color: black !important;
    color: #fff !important;
}

.mobile-dropdown-menu-wrapper>div>a {
    font-size: 14px;
    font-weight: 500;
}


.mobile-dropdown-menu-title {
    font-weight: 600 !important;
    font-size: 16px !important;
}

/*
.mobile-dropdown-item {
    padding-top: 8px;
    padding-bottom: 8px;
}

.mobile-dropdown-item:hover {
    transition: all 0.3s ease;
    background-color: #4ae84d2f !important;
} */